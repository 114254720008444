/* eslint-disable no-mixed-operators */
import React from "react"
import * as Adm from "@adm"
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextareaAutosize,
} from "@material-ui/core"
import _ from "lodash"
import { makeStyles, withStyles } from "@material-ui/core/styles"

const CustomRadio = withStyles({
  root: {
    "&$checked": {
      color: "#df86bb",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const regex = (value) => value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) === null

const useStyles = makeStyles((theme) => ({
  textInput: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
}))
const CustomTextField = (props) => {
  const classes = useStyles()
  const { isError = false, errorMessage = "is Required", ...restProps } =
    props || {}
  return (
    <Grid style={{ minHeight: 60 }}>
      <TextField {...restProps} className={classes.textInput} />
      {isError && (
        <Typography style={{ color: "red", fontSize: 12 }}>
          {errorMessage}
        </Typography>
      )}
    </Grid>
  )
}

export const renderTaxConfigDP = (props, t) => {
  return (
    <Grid>
      <FormControl>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          <span
            style={{ color: "black", fontWeight: "bold" }}
          >{t(`Select Tax Type`)}</span>
          <span style={{ color: "red" }}>{`*`}</span>
          <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
        </Typography>
        <RadioGroup
          name="taxconfigName"
          value={props?.getTaxConfig?.taxconfigName}
          onChange={(event) => {
            props?.onChangeValueTextField({
              value: event?.target?.value,
              fieldName: "taxconfigName",
            })
          }}
        >
          <FormControlLabel
            value="avalara"
            disabled={props?.getTaxConfig?.is_avalara === false}
            control={<CustomRadio name="radio-button-demo" />}
            label={t("Avalara")}
          />
          <FormControlLabel
            value="customTax"
            control={<CustomRadio name="radio-button-demo" />}
            label={t("Custom Tax Engine")}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  )
}

export const renderAccountNumber = (props) => {
  const { crudType = "", t } = props
  return (
    <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`Account Number`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        name="accountNumber"
        type="number"
        inputProps={{
          maxLength: 64,
        }}
        disabled={crudType === "view"}
        errorMessage={t(`Account Number Required`)}
        isError={
          props?.getIsErrorIntiated && props?.getTaxConfig?.accountNumber === ""
        }
        value={props?.getTaxConfig?.accountNumber}
        style={{
          width: "100%",
          backgroundColor: crudType === "view" ? "#efefef" : "inherit",
        }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the Account Number")}
        onKeyPress={(e) => e.which === 101 && e.preventDefault()}
        onPaste={(event) => {
          if (event.type === "paste") {
            var clipboardData = event.clipboardData || window.clipboardData;
            var pastedData = clipboardData.getData('Text');
            if (isNaN(pastedData)) {
              event.preventDefault();
            } else {
              return;
            }
          }
        }}
        onChange={(event) => {
          console.log(event.target.value, "accountNumber ochange")
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "accountNumber",
          })
        }}
      />
    </Grid>
  )
}
export const renderLicenseKey = (props) => {
  const { crudType = "", t } = props
  return (
    <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`License Key`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        inputProps={{
          maxLength: 64,
        }}
        className="cls-number-ui-txt"
        errorMessage={t(`License Key Required`)}
        isError={
          props?.getIsErrorIntiated && props?.getTaxConfig?.licenseKey === ""
        }
        name="licenseKey"
        value={props?.getTaxConfig?.licenseKey}
        disabled={crudType === "view"}
        style={{
          width: "100%",
          backgroundColor: crudType === "view" ? "#efefef" : "inherit",
        }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the License Key")}
        onKeyPress={(e) => e.which === 32 && e.preventDefault()}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value,
            fieldName: "licenseKey",
          })
        }}
      />
    </Grid>
  )
}
export const renderHeader = (props) => {
  const { crudType = "", t } = props
  return (
    <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`Header`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        inputProps={{
          maxLength: 64,
        }}
        className="cls-number-ui-txt"
        errorMessage={t(`Header Required`)}
        isError={
          props?.getIsErrorIntiated && props?.getTaxConfig?.header === ""
        }
        name="header"
        value={props?.getTaxConfig?.header?.replace(/(\s{2,})/g, " ")}
        disabled={crudType === "view"}
        style={{
          width: "100%",
          backgroundColor: crudType === "view" ? "#efefef" : "inherit",
        }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the Header")}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value?.replace(/(\s{2,})/g, " "),
            fieldName: "header",
          })
        }}
      />
    </Grid>
  )
}
export const renderEnvironment = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={5} md={5} lg={5} xl={5} style={{ marginBottom: 10 }}>
      <FormControl>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          <span
            style={{ color: "black", fontWeight: "bold" }}
          >{t(`Environment`)}</span>
          <span style={{ color: "red" }}>{`*`}</span>
          <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
        </Typography>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="environment"
          value={props?.getTaxConfig?.environment}
          onChange={(event) => {
            props?.onChangeValueTextField({
              value: event?.target?.value,
              fieldName: "environment",
            })
          }}
        >
          <FormControlLabel
            disabled
            value="sandbox"
            control={<CustomRadio />}
            label={t("Sandbox")}
          />
          <FormControlLabel
            disabled
            value="production"
            control={<CustomRadio />}
            label={t("Production")}
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  )
}
export const renderCompanyCode = (props) => {
  const { crudType = "", t } = props
  return (
    <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`Company Code Lookup`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        name="companyCodeLookup"
        inputProps={{
          maxLength: 64,
        }}
        errorMessage={t(`Company Code Required`)}
        isError={
          props?.getIsErrorIntiated &&
          props?.getTaxConfig?.companyCodeLookup === ""
        }
        value={props?.getTaxConfig?.companyCodeLookup?.replace(/(\s{2,})/g, " ")}
        disabled={crudType === "view"}
        style={{
          width: "100%",
          backgroundColor: crudType === "view" ? "#efefef" : "inherit",
        }}
        variant="outlined"
        size="small"
        placeholder={t("Enter Company Code Lookup")}
        onChange={(event) => {
          props?.onChangeValueTextField({
            value: event?.target?.value.replace(/(\s{2,})/g, " "),
            fieldName: "companyCodeLookup",
          })
        }}
      />
    </Grid>
  )
}

export const renderAPIEndpointTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`API End Point URL`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        name="apiUrl"
        value={props?.nameKey}
        inputProps={{
          maxLength: 255,
        }}
        errorMessage={props?.getIsErrorIntiated && (props?.nameKey === "" ? t(`API Url Required`) :
          regex(props?.nameKey) ? t(`Enter Valid URL`) : "")}
        isError={props?.getIsErrorIntiated && (props?.nameKey === "" || regex(props?.nameKey))}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the url")}
        onChange={(event) => {
          props?.onChangeValueInObject({
            value: event?.target?.value,
            fieldName: "apiUrl",
            fieldName1: props?.fieldName1,
          })
        }}
      />
    </Grid>
  )
}

export const renderConnectionMethodDP = (props) => {
  const { getCMDropdownValue, getCMDropdownList, setCMDropdownValue, t } =
    props || {}

  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`Authentication`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <Adm.DropdownKit
        style={{ width: "100%" }}
        defaultValue={props?.nameKey}
        list={getCMDropdownList}
        onUpdate={(e) => {
          props?.onChangeValueInObject({
            value: e,
            fieldName: "connectionMethod",
            fieldName1: props?.fieldName1,
          })
        }}
      />
    </Grid>
  )
}
export const renderUserNameTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>{t(`UserName`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        name="userName"
        value={props?.nameKey}
        inputProps={{
          maxLength: 64,
        }}
        errorMessage={t(`User Name Required`)}
        isError={props?.getIsErrorIntiated && props?.nameKey === ""}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the User Name")}
        onChange={(event) => {
          props?.onChangeValueInObject({
            value: event?.target?.value,
            fieldName: "userName",
            fieldName1: props?.fieldName1,
          })
        }}
      />
    </Grid>
  )
}
export const renderPasswordTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>{t(`Password`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        name="password"
        value={props?.nameKey}
        inputProps={{
          maxLength: 32,
        }}
        errorMessage={props?.getIsErrorIntiated && (props?.nameKey === "" ? t(`Password Required`) :
          props?.nameKey?.length < 2 ? t(`Min-2 characters Required`) : "")}
        isError={props?.getIsErrorIntiated && props?.nameKey === "" || props?.nameKey?.length < 2}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the password")}
        onChange={(event) => {
          props?.onChangeValueInObject({
            value: event?.target?.value,
            fieldName: "password",
            fieldName1: props?.fieldName1,
          })
        }}
      />
    </Grid>
  )
}
export const renderTokenTF = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>{t(`Token`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <CustomTextField
        errorMessage={props?.getIsErrorIntiated && (props?.nameKey === "" ? t(`Token Required`) :
          props?.nameKey?.length < 4 ? t(`Min-4 characters Required`) : "")}
        isError={props?.getIsErrorIntiated && props?.nameKey === "" || props?.nameKey?.length < 4}
        name="token"
        value={props?.nameKey}
        inputProps={{
          maxLength: 128,
        }}
        style={{ width: "100%" }}
        variant="outlined"
        size="small"
        placeholder={t("Enter the Token")}
        onKeyPress={(e) => { if (e.which === 32) e.preventDefault(); }}
        onChange={(event) => {
          props?.onChangeValueInObject({
            value: event?.target?.value,
            fieldName: "token",
            fieldName1: props?.fieldName1,
          })
        }}
      />
    </Grid>
  )
}
export const renderResponseTypeDP = (props) => {
  const { getRTDropdownValue, getRTDropdownList, setRTDropdownValue, t } =
    props || {}
  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span
          style={{ color: "black", fontWeight: "bold" }}
        >{t(`Response Type`)}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <Adm.DropdownKit
        style={{ width: "100%", height: "60px" }}
        defaultValue={props?.nameKey}
        list={getRTDropdownList}
        onUpdate={(e) => {
          props?.onChangeValueInObject({
            value: props?.getDefaultSampleCode[e],
            fieldName: "sampleTestCode",
            fieldName1: props?.fieldName1,
          })
          props?.onChangeValueInObject({
            value: e,
            fieldName: "responseType",
            fieldName1: props?.fieldName1,
          })
        }}
      />
    </Grid>
  )
}

export const renderSuccessStatusCodeTF = (props) => {
  const { t } = props
  return (
    <Adm.ChipKit
      defaultCodeList={
        props?.nameKey.length > 0
          ? props?.nameKey
          : []
      }
      bgColor="#9ED38B"
      title={t("Success Status Code(s)")}
      fieldName="statusCode"
      fieldName1={props?.fieldName1}
      onUpdate={(e) => {
        props?.onChangeValueInObject({
          value: e?.list,
          fieldName1: props?.fieldName1,
          fieldName: "statusCode",
        })
      }}
    />
  )
}
export const renderErrorStatusCodeTF = (props) => {
  const { t } = props
  return (
    <Adm.ChipKit
      defaultCodeList={
        props?.nameKey.length > 0
          ? props?.nameKey
          : []
      }
      bgColor="#EF9E9E"
      title={t("Error Status Code(s)")}
      fieldName="statusErrorCode"
      onUpdate={(e) => {
        props?.onChangeValueInObject({
          value: e?.list,
          fieldName1: props?.fieldName1,
          fieldName: "statusErrorCode",
        })
      }}
    />
  )
}

export const renderSampleTestCode = (props) => {
  const { t } = props
  return (
    <Grid item xs={12} sm={12} md={12} lg={10}>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          <span
            style={{ color: "black", fontWeight: "bold" }}
          >{t(`Sample Response`)}</span>
          <span style={{ color: "red" }}>{`*`}</span>
          <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
        </Typography>
        <Typography style={{ fontWeight: "bold", color: "#CE5E5E" }}>
          {props?.responseType}
        </Typography>
      </Grid>

      <TextareaAutosize
        key={props?.nameKey}
        style={{
          resize: "none",
          border: "1px solid #C7C7C7",
          padding: 10,
          borderRadius: 8,
          width: "100%",
          height: 200,
          minHeight: 200,
          maxHeight: 200,
        }}
        defaultValue={props?.nameKey}
        onChange={(event) => {
          _.debounce(() => {
            props?.onChangeValueInObject({
              value: event?.target?.value,
              fieldName1: props?.fieldName1,
              fieldName: "sampleTestCode",
            })
          }, 1000)
        }}
        onBlur={(event) => {
          props?.onChangeValueInObject({
            value: event?.target?.value,
            fieldName1: props?.fieldName1,
            fieldName: "sampleTestCode",
          })
        }}
      />
    </Grid>
  )
}
