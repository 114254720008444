export const API_END_POINTS = {
  signIn: "/api/auth/sign-in",
  signUp: "/api/auth/sign-up",
  reset: "/api/auth/reset-password",
  getAllCountries: "/api/countries/getCountries",
  getAllCities: "/api/stores/getAllCities",
  getAllStates: "/api/stores/getAllRegions",
  getAllBrands: "/api/products/getAllBrands",
  getAllManufacturers: "/api/products/getAllManufacturers",
  addCountry: "/api/countries/create",
  updateCountry: "/api/countries/update",
  deleteCountry: "/api/countries/delete",
  getAllCustomerList: "/api/customer/getcustomers",
  deleteManyCountry: "/api/countries/deletemany",
  getAllCurrencies: "/api/currency/getcurrency",
  getAllCustomers: "/api/userprofile/users/allCustomers",
  customerInfoValidationCheck: "/api/customer/customerInfoValidation",
  getCustomersPaginated: "/api/userprofile/users/userpaginate",
  getSocialProfile: "/api/customer/getsocialprofiles",
  getEthnicity: "/api/customer/getethnicity",
  getcustomeracquisitionsource: "/api/customer/getcustomeracquisitionsource",
  getAllPrices: "/api/prices",
  addNewAddress: "/api/userprofile/users/newaddress",
  editAddress: "/api/userprofile/users/editaddress",
  addCurrency: "/api/currency/create",
  updateCurrency: "/api/currency/update",
  deleteCurrency: "/api/currency/delete",
  deleteManyCurrency: "/api/currency/deletemany",
  getAllProvinces: "/api/province",
  addProvinces: "/api/province/create",
  updateProvinces: "/api/province/update",
  deleteProvinces: "/api/province/delete",
  deleteManyProvinces: "/api/province/deletemany",
  getAllMeasure: "/api/measurement/getuom",
  addMeasure: "/api/measurement/create",
  updateMeasure: "/api/measurement/update",
  deleteMeasure: "/api/measurement/delete",
  deleteManyMeasure: "/api/measurement/deletemany",
  getAllState: "/api/state/states",
  addState: "/api/state/create",
  updateState: "/api/state/update",
  deleteState: "/api/state/delete",
  deleteManyState: "/api/state/deletemany",
  getAllLanguage: "/api/language/fetchLanguages",
  addLanguage: "/api/language/create",
  updateLanguage: "/api/language/update",
  deleteLanguage: "/api/language/delete",
  deleteManyLanguage: "/api/language/deletemany",
  getLanguageData: "/api/language/getLanguageData",
  addLanguageMaster: "/api/language/addLanguageMaster",
  updateLanguageMaster: "/api/language/updateLanguageMaster",
  deleteLanguageMaster: "/api/language/deleteLanguageMaster",
  updateIndividualLanguage: "/api/language/updateLanguage",
  getAllBarcodeTypes: "/api/barcode/getbarcodes",
  addBarcodeTypes: "/api/barcode/create",
  updateBarcodeTypes: "/api/barcode/update",
  deleteBarcodeTypes: "/api/barcode/delete",
  deleteManyBarcodeTypes: "/api/barcode/deletemany",
  getAllGender: "/api/gender/getGender",
  getTaxTypes: "/api/taxs/getTaxTypes",
  getLanguage: "/api/language/getLanguages",
  getAllLanguageConfigList: "/api/language/getLanguageConfigList",
  getCurrencies: "/api/currency/getCurrencies",
  getGender: "/api/gender",
  addGender: "/api/gender/create",
  updateGender: "/api/gender/update",
  deleteGender: "/api/gender/delete",
  deleteManyGender: "/api/gender/deletemany",
  addLocation: "/api/stores/create",
  getAllStores: "/api/stores/getlocations",
  getAllProducts: "/api/products/getProducts",
  getMultipleProductMapping: "/api/products/getMultipleProductMapping",
  getLocation: "/api/stores/location",
  getAnStore: "/api/stores/store",
  getLocationGroup: "/api/stores/locationgroup",
  deleteAnStore: "/api/stores/delete",
  deleteManyStore: "/api/stores/deletemany",
  deleteCustomers: "/api/customer/deletecustomers",
  createCustomer: "/api/customer/createcustomer",
  addCustomer: "/api/auth/signup",
  getAnCustomer: "/api/customer/getcustomerbyid",
  updateAnCustomer: "/api/customer/updatecustomer",
  getAnPrice: "/api/prices/price",
  updateAnPrice: "/api/prices/update",
  getAllInventory: "/api/inventory/getinventories",
  deleteInventories: "/api/inventory/deletemany",
  getInventoryPaginated: "/api/inventory/inventorypaginate",
  createPrice: "/api/prices/create",
  deletePrice: "/api/prices/delete",
  createProduct: "/api/products/create",
  UploadProductImage: "/api/products/upload",
  priceLocationElasticSearch: "/api/searchprice/locations?searchTerm=",
  getProductPaginated: "/api/products/productpaginate",
  getGroupById: "/api/stores/getGroupById",
  updateGroup: "/api/stores/updateGroup",
  createLocationGroup: "/api/stores/createlocationgroup",
  deleteStoreGroup: "/api/stores/deleteGroupStore",
  getlocationtypes: "/api/location/getlocationtypes",
  getlocationservices: "/api/location/getlocationservices",
  checkLocationExist: "/api/stores/checkLocationExist",
  updateLocation: "/api/stores/updateLocation",
  updateLocationGroup: "/api/stores/updatelocationgroup",
  getlocationgroups: "/api/stores/getlocationgroups",
  getLocationByGroupId: "/api/stores/getLocationByGroupId",
  getproductbygroupId: "/api/products/getproductbygroupId",
  getProductGroup: "/api/products/productgroup",
  updateproductgroup: "/api/products/updateproductgroup",
  createproductgroup: "/api/products/createproductgroup",
  getTenantIdByName: "/api/tenant/getTenantIdByName",
  updateRolePermissionList: "/api/roles/updateRolePermission",
  updateRoleAttributesByTenantId: "/api/roles/updateRoleAttributesByTenantId",
  updateRoleAttributesByRoleId: "/api/roles/updateRoleAttributesByRoleId/",
  deleteManyAttributes: "/api/products/deleteProductAttributes",
  getTurnstiles: "/api/sdc/getturnstiledetails",
  getGondolas: "/api/gondola/getgondoladetails",
  getGondolaById: "/api/gondola/getgondola?",
  getCameras: "/api/sdc/getcameradetails",
  getFSPos: "/api/kc/getposdetails",
  getFSScanner: "/api/kc/getScannerDetails",
  getFSPrinter: "/api/kc/getPrinterDetails",
  getFSTerminalScreen: "/api/kc/getTerminalScreenDetails",
  getFSEdgeDevice: "/api/kc/getedgedevicedetails",
  getEdgeDeviceHealth: "/api/sdc/getedgehealth",
  listplanogram: "/api/sdc/listplanogram",
  listPlanogramFS: "/api/kc/listplanogram",
  listplanogramtest: "/api/sdc/listplanogram",
  getTaxCodes: "/api/taxs/getTaxCodes",
  createBusinessUser: "/api/tenant/createBusinessUser",
  updateBusinessUser: "/api/tenant/updateBusinessUser",
  updateTenantAdminProfile: "/api/tenant/updateTenantAdminProfile",
  getRoleById: "/api/tenant/getRoleById/",
  getRoles: "/api/roles/getRoles/",
  getRolesByTenantId: "/api/tenant/getRoles",
  deleteRole: "/api/roles/deleteByRoleId",
  deleteUser: "/api/user/delete",
  getBusinessUsers: "/api/tenant/getBusinessUsers",
  createTenantUser: "/api/tenant/createTenantwithAdmin",
  getTenantDetailWithAdmin: "/api/tenant/getTenantDetailWithAdmin",
  getBusinessUserbyId: "/api/tenant/getBusinessUserbyId",
  getUserinfo: "/api/kc/getUserInfo",
  updateTenantDetail: "/api/tenant/updateTenantDetail",
  getTenantsUsers: "/api/tenant/getTenants",
  updateTenantAddress: "/api/tenant/updateTenantAddress",
  getRolePermissionDetail: "/api/roles/getRolePermissionDetail",
  bulkUploadProducts: "/api/products/bulkUploadProducts",
  getDashBoardStore: "/api/stores/getStoresforDashboard",
  getTenantDashBoard: "/api/tenant/getTenantforDashboard",
  getRoleAttributesByTenantId: "/api/roles/getRoleAttributesByTenantId",
  getSassAdminRoles: "/api/roles/getRolesDetailByRolename",
  getProductMappingDetails: "/api/products/getProductMapping",
  getProductMap: "/api/smartshelf/getproduct?",
  updateProductMappingDetails: "/api/products/updateProductMapping",
  createProductMappingDetails: "/api/products/createProductMapping",
  deleteProductMappings: "/api/products/deleteProductMappings",
  getAllProductItemIdList: "/api/products/getAllProductItemIdList",
  getRetailersList: "",
  exportAllProductMappings: "/api/products/exportAllProductMappings",
  bulkUploadProductMappings: "/api/products/bulkUploadProductMappings",
  getTaxFields: "/api/tax/getTaxFields/",
  getCountryTaxFields: "/api/tax/getCountryTaxFields",
  generateOtp: "/api/tenantauth/generateOTP",
  verifyOtp: "/api/tenantauth/verifyOTP",
  updatePassword: "/api/tenant/updatePassword",
  verifyPassword: "/api/tenant/verifyPassword",
  createTenantRole: "/api/tenant/createRole",
  updateRoleAttributes: "/api/roles/updateRoleAttributesByRoleId/",
  getActiveRolesByTenant: "/api/tenant/getActiveRolesByTenantId",
  getDefaultPermissions: "/api/tenant/getDefaultRoles/",
  getProductConfigList: "/api/masterdata/api/product/getProductConfigList",
  deleteProductConfig: "/api/masterdata/api/product/deleteProductConfig",
  createPaymentConfig: "/api/payment/api/gatewayconfig/createPaymentConfig",
  getPaymentConfig: "/api/payment/api/gatewayconfig/getPaymentConfig/",
  getCloverRegions: "/api/payment/api/gatewayconfig/getCloverRegionList",
  getPaymentKey: "/api/payment/api/gatewayconfig/getPaymentKey",
  updatePaymentKey: "/api/payment/api/gatewayconfig/updatePaymentKey/",
  deletePaymentConfig: "/api/payment/api/gatewayconfig/deletePaymentConfig/",
  refundPayment: "/api/payment/api/refund/process/refund/",
  checkRefund: "/api/payment/api/refund/checkrefund",
  masterDataModule: "/api/masterdata",
  auditLogs: "/api/auditlogs/getAuditLogsList",
  auditLogModuleNames: "/api/auditlogs/getAuditLogsSubmodules",
  createAuditLog: "/api/auditlogs/createAuditLogs",
  getTransaction: "/api/transaction/searchTransactions",
  getInvoiceTransactions: "/api/transaction/invoiceTransactions",
  getSummaryTransactions: "/api/transaction/storeInvoiceSummary",
  getTokenAuth: "/api/kc/token",
  getForgetPasswordLink: "/forgot",
  resetPassword: "/reset/password",
  getAuthToken: "/reset/",
  getcardtype: "/getcardtype",
  uploadModel: "/api/stores/updateModel",
  getListScheduledReports: "/api/reports/getListScheduledReports",
  createScheduledReports: "/api/reports/createScheduledReports",
  updateScheduledReports: "/api/reports/updateScheduledReports",
  deleteScheduledReports: "/api/reports/deleteScheduledReports",
  getScheduledReports: "/api/reports/getScheduledReports",
  getReportsTimezone: "/api/reports/getTimezone",
  createAdminRole: "/api/admin/roles/createAdminRole",
  getAdminRoleById: "/api/admin/roles/getAdminRoleById/",
  getAdminRoles: "/api/admin/roles/getAdminRoles/",
  deleteAdminRole: "/api/admin/roles/deleteAdminRoleById",
  getAdminUsers: "/api/admin/users/getAdminUsers",
  getAdminBusinessUserById: "/api/admin/users/getBusinessUserbyId",
  createAdminUser: "/api/admin/users/createAdminUser",
  updateAdminUser: "/api/admin/users/updateBusinessUser",
  updateAdminRoleAttributes:
    "/api/admin/roles/updateAdminRoleAttributesByRoleId/",
  getAdminDefaultRoles: "/api/admin/roles/getAdminDefaultRoles/",
  deleteAdminUser: "/api/admin/users/AdminUserdelete",
  autheticateUser: "/api/kc/api/user/authenticate",
  /**
   * @ Alert setup
   */

  alertAll: "/api/alerts/setup/alert/all",
  levelAll: "/api/alerts/setup/level/all",
  severityAll: "/api/alerts/setup/severity/all",
  entityFilterByIds: "/api/alerts/setup/entity/filter/by/ids",
  alertCreate: "/api/alerts/setup/alert/create",
  alertUpdate: "/api/alerts/setup/alert/update",
  configurationTenantList: "/api/alerts/configuration/all",
  unConfiguredListByTenant: "/api/alerts/setup/alert/unconfigured/by/tenant",
  monitoringTenantList: "/api/alerts/monitoring/all",
  configurationMultipleSave: "/api/alerts/configuration/save/multiple",
  configurationUpdate: "/api/alerts/configuration/update",
  monitorUpdate: "/api/alerts/monitoring/update",
  configurationRoleBySubscription:
    "/api/alerts/configuration/role/by/subscription",
  dashboardAlertRealm: "/api/alerts/monitoring/count/by/realm",
  dashboardAlertTenant: "/api/alerts/monitoring/count/by/tenant",
  dashboardAlertStore: "/api/alerts/monitoring/count/by/location",
  augmentation: "/api/augmentation/startAugumentation",
  printReceiptList: "/api/receipts/getReceiptList",
  createPrintReceipt: "/api/receipts/createReceipt",
  deletePrintReceipt: "/api/receipts/deleteReceipt/",
  updatePrintReceipt: "/api/receipts/updateReceipt/",
  printReceipt: "/api/receipts/getReceipt/",
  refreshTokenAPI: "/api/kc/RefreshToken",
}
