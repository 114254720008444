import React from "react"
import { Grid, Container, Card, IconButton } from "@material-ui/core"
import * as Adm from "@adm"
import * as StyledDOM from "./styles"
import * as Fixture from "./fixture"
import * as IconsGallery from "@iconsGallery"
import * as Deps from "../deps"
import ClearIcon from "@material-ui/icons/Clear"

export const renderRetailerUI = (props) => {
  const { mainStateDashboard, t } = props
  const renderView = renderCardView

  return renderView({ mainStateDashboard, t })
}

export const renderCardView = (props) => {
  const { mainStateDashboard, t } = props
  const defaultList = Deps.getDefaultList(mainStateDashboard, {}, t)
  let tabIndex = defaultList[mainStateDashboard.getTabIdValue()]?.identifer

  const handleListView = (value) => {}
  const handleClick = (value = {}) => {
    mainStateDashboard.handleSaveTenantId({ value })
    mainStateDashboard.onUpdate(value?.id, "selectedTenantId", value, true)
  }

  const handleChangePage = (event, newPage) => {
    let pageCount = newPage + 1
    console.log(pageCount, newPage, "handleChangePage")
    mainStateDashboard.setPageNumber(newPage)
    mainStateDashboard.onUpdate(newPage, "pageNumber")
    mainStateDashboard.getAPIRetailerList({
      identifer: tabIndex,
      isActiveSyncLoad: true,
      pageNumber: pageCount,
      rowsPerPage: mainStateDashboard.rowsPerPage,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const pageRows = parseInt(event.target.value, 10)
    let dataUpdate = mainStateDashboard?.defaultList
    dataUpdate["pageNumber"] = 0
    dataUpdate["rowsPerPage"] = pageRows
    mainStateDashboard.setTenantConfig({ ...dataUpdate })
    mainStateDashboard.setRowsPerPage(pageRows)
    mainStateDashboard.setPageNumber(0)
    mainStateDashboard.getAPIRetailerList({
      identifer: tabIndex,
      isActiveSyncLoad: true,
      pageNumber: 1,
      rowsPerPage: pageRows,
    })
  }

  let list = Fixture.retailerDataList({ mainStateDashboard })

  const tablePaginationProps = {
    pageNumber: mainStateDashboard.pageNumber,
    setPageNumber: mainStateDashboard.setPageNumber,
    rowsPerPage: mainStateDashboard.rowsPerPage,
    setRowsPerPage: mainStateDashboard.setRowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    totalRecords: mainStateDashboard?.fetchAPIRetailerList?.total,
    totalPages: Math.ceil(
      mainStateDashboard?.fetchAPIRetailerList?.total /
        mainStateDashboard.rowsPerPage
    ),
    searchText: mainStateDashboard.searchValue,
  }

  const handleSearch = (e) => {
    mainStateDashboard.setSearchValue(e.target.value)
    if (e.target.value.length > 3) {
      triggerRetailerAPIList(e.target.value)
    } else if (e.target.value.length === 0) {
      triggerRetailerAPIList("")
    }
  }

  const handleSearchBluredField = (event) => {
    mainStateDashboard.setSearchValue(event.target.value)
    handleSearchButton()
  }

  const handleSearchEnter = (e) => {
    if (e.which === 13) {
      handleSearchButton()
    }
  }
  const handleSearchButton = (e) => {
    const value = mainStateDashboard.refFieldValue.current.querySelector(
      "input"
    ).value
    mainStateDashboard.setSearchValue(value)
    mainStateDashboard.onUpdate(value, "searchTenantValue")
    triggerRetailerAPIList(value)
  }

  const handleClearSearchEnter = (e) => {
    mainStateDashboard.setSearchValue("")
    mainStateDashboard.onUpdate("", "searchTenantValue")
    triggerRetailerAPIList()
  }

  const triggerRetailerAPIList = (value) => {
    let dataUpdate = mainStateDashboard?.defaultList
    dataUpdate["pageNumber"] = 0
    mainStateDashboard.setTenantConfig({ ...dataUpdate })
    mainStateDashboard.setPageNumber(0)
    mainStateDashboard.getAPIRetailerList({
      identifer: tabIndex,
      isActiveSyncLoad: true,
      pageNumber: 1,
      rowsPerPage: mainStateDashboard?.rowsPerPage,
      searchText: value,
    })
  }

  return (
    <>
      <div style={{ minHeight: "70vh" }}>
        <Grid container item xs style={{ padding: "10px" }}>
          <StyledDOM.SearchBox
            ref={mainStateDashboard.refFieldValue}
            variant="outlined"
            placeholder={t("Search")}
            size="small"
            defaultValue={mainStateDashboard.searchValue}
            onKeyDown={(e) => handleSearchEnter(e)}
          />
          {mainStateDashboard.searchValue ? (
            <IconButton
              style={{
                backgroundColor: "#D38BB9",
                borderRadius: "0px 4px 4px 0px",
                width: "36px",
                height: "37.5px",
              }}
              size="small"
              variant="outlined"
              onClick={(e) => handleClearSearchEnter(e)}
            >
              <ClearIcon style={{ color: "white" }} />
            </IconButton>
          ) : (
            <IconButton
              style={{
                backgroundColor: "#D38BB9",
                borderRadius: "0px 4px 4px 0px",
                width: "36px",
                height: "37.5px",
              }}
              size="small"
              variant="outlined"
              onClick={(e) => handleSearchButton(e)}
            >
              <IconsGallery.SearchIcon style={{ color: "white" }} />
            </IconButton>
          )}
        </Grid>
        <StyledDOM.StyledDashboardCardView>
          {mainStateDashboard.isLoading ? (
            <Grid>{`${t("Loading")}...`}</Grid>
          ) : list?.length > 0 ? (
            <>
              {list?.map((itm, index) => {
                const cardViewProps = {
                  ...itm,
                  list: itm,
                  handleListView: handleClick,
                  index,
                  tenantListview: true,
                  mainStateDashboard,
                }
                return <Adm.CardView {...cardViewProps} />
              })}
            </>
          ) : (
            <h1>{t("Retailer Not Found")}</h1>
          )}
        </StyledDOM.StyledDashboardCardView>
      </div>
      <Adm.CustomTablePagination {...tablePaginationProps} />
    </>
  )
}
