import React from "react"
import * as Adm from "@adm"
import { Grid } from "@material-ui/core"
import * as Deps from "./deps"
import * as StyledDOM from "./styles"
import useDashboardState from "./useDashboardState"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import _ from "lodash"

function SassDashboard(props) {
  const { t } = useTranslation()
  const userPermAttr =
    useSelector((state) => state.adminReducer.userKeycloakAttributes) || {}
  const stateDashboard = useDashboardState({ ...userPermAttr, ...props })
  const defaultList = Deps.getDefaultList(stateDashboard, props, t)

  React.useEffect(() => {
    let isSAASRole = _.includes(localStorage.getItem("role"), "SaaS-Site-Admin")
    if(isSAASRole) {
     return() => {
       localStorage.removeItem("tempTenantId")
     }
   }
   },[])

  React.useEffect(() => {
    Deps.triggerSearchParam({ stateDashboard }, props, t)
  }, [])

  // remove tenant values when click reset
  React.useEffect(() => {
    const { defaultList = {} } = props
    if (defaultList?.selectedTenantId !== stateDashboard?.selectedTenantId) {
      stateDashboard?.setSelectedTenantId(
        props?.defaultList?.selectedTenantId || ""
      )
      stateDashboard?.setSearchValue(
        props?.defaultList?.searchTenantValue || ""
      )
      stateDashboard?.setPageNumber(props?.defaultList?.pageNumber || 0)
      stateDashboard?.setRowsPerPage(props?.defaultList?.rowsPerPage || 30)
      stateDashboard?.setFetchAPIRetailerList(
        props?.defaultList?.tenantList || stateDashboard?.defaultRetailerList
      )
      stateDashboard.getAPIRetailerList({
        isActiveSyncLoad: true,
        pageNumber: 1,
        rowsPerPage: props?.defaultList?.rowsPerPage || 30,
      })
    }
  }, [props?.defaultList])

  React.useEffect(() => {
    props?.setTenantConfig({
      ...props?.defaultList,
      tenantList: { ...stateDashboard?.fetchAPIRetailerList },
    })
  }, [stateDashboard?.fetchAPIRetailerList])

  React.useEffect(() => {
    if (
      props?.getIsTenantModalOpen && props?.defaultList?.tenantList?.length === 0
    ) {
      stateDashboard.getAPIRetailerList({
        isActiveSyncLoad: true,
        pageNumber: 1,
        rowsPerPage: props?.defaultList?.rowsPerPage || 30,
      })
    }
  }, [props?.getIsTenantModalOpen])

  const renderTab = () => {
    const tabListProps = {
      list: defaultList,
      defaultIndex: 1,
      isIconEnable: true,
      onUpdate: (props) => {
        const { index = 0 } = props
        const { qureyName = "" } = defaultList[index]
        stateDashboard?.setPageNumber(0)
        stateDashboard?.setRowsPerPage(15)
        let dynamicRoutes = t(`/settings/productcatalog/create`)
        stateDashboard?.pageHistory.push(dynamicRoutes)
        stateDashboard.setSearchValue("")
      },
      className: "cls-main-tab",
      displayHeader: false,
    }
    return (
      <StyledDOM.StyledTabUI>
        {stateDashboard?.currentMainTabIndex !== null && (
          <Adm.Tab {...tabListProps} />
        )}
      </StyledDOM.StyledTabUI>
    )
  }

  return <Grid>{renderTab()}</Grid>
}

export default SassDashboard
export { SassDashboard }
