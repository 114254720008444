import { Grid, Paper } from "@material-ui/core"

const MaintenancePage = (props) => {
  const { title = "" } = props
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Grid
        elevation={6}
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "30px 20px",
          backgroundColor: "#fff",
        }}
      >
        <img src="/static/img/maintenancePage.svg" alt="Maintenance Page" />
        <div style={{ paddingLeft: "20px" }}>
          <div
            style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.54)" }}
          >{`Welcome To the ${title} Page`}</div>
          <div
            style={{
              fontSize: "12px",
              color: "rgba(0, 0, 0, 0.54)",
              marginTop: "10px",
            }}
          >
            Get started by selecting one of the filter criteria above to
            proceed.
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
export default MaintenancePage
