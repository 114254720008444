import * as React from "react"
import { UploadIcon } from "@iconsGallery"
import { ExportButton, PortButton } from "./styles"
import { ExcelExport } from "@progress/kendo-react-excel-export"
import _ from "lodash"
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import { CloseRounded } from "@material-ui/icons"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles({
  card: {
    width: "48%",
    padding: "10px",
    boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.07)",
    '@media(max-width:700px)': {
      width: "100%",
      margin: "10px",
      display: "flex",
      flexDirection: "column",
    },
  },
  radioGroupWrap: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
  },
  firstHead: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: "17px",
  },
  secondHead: {
    textAlign: "center",
    padding: "10px",
  },
  clr10: {
    height: "10px",
    clear: "both",
  },
  imgWrap: {
    textAlign: "center",
    margin: "10px 0px 20px 0px",
  },
  radioBtn: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiFormControlLabel-root": {
      marginRight: "0px",
    },
    "& .MuiButtonBase-root": {
      padding: "0px",
    },
  },
  DialogMainWrapper: {
    height: "100%",
    width: "750px",
    margin: "auto",
    '@media(max-width:700px)': {
      width: "100%",
      padding: "20px 5px",
      display: "flex",
      flexDirection: "column",
    },
  },
  selectField: {
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
  }
})


const ExportButtonUI = (props) => {
  const { t } = useTranslation()
  const {
    file = "sampleExport.csv",
    label = "Export",
    exportGridData = [],
    columnData = [],
    handleExportFormat = () => { },
    disabled = false,
  } = props || {}
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles()
  const _export = React.useRef(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [extensionValue, setExtensionValue] = React.useState("xls")
  const [exportTypeVal, setExportTypeVal] = React.useState("")
  const [fileName, setFileName] = React.useState(`${file}`)
  const [exportFormatData, setExportFormatData] = React.useState([])
  const handleClick = () => {
    setIsOpen(true)
    setExportTypeVal("")
    setExtensionValue("xls")
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const excelExport = async (type) => {
    console.log(exportGridData, "exportGridDataRecords")
    let sort = exportGridData?.sort.reduce((acc, o) => {
      acc[o.field] = o.dir === "asc" ? 1 : -1
      return acc
    }, {})

    let bodyData = {}
    if (type === "currentView") {
      bodyData = {
        filter: exportGridData?.filter?.filters,
        sort,
        limit: exportGridData.limit,
        page: exportGridData.page,
      }
    }
    let column = columnData.map((x => {
      return { ...x, "title": t(x.title) }
    }))
    console.log("columnData", column)
    let exportFormatData = await handleExportFormat(bodyData, type)
    setExportFormatData(exportFormatData)
    if (_export.current !== null) {
      _export.current.save(
        exportFormatData,
        _.filter(column, _.matches({ show: true }))
      )
    }
    await handleClose()
  }

  const handleExport = () => {
    setFileName(file + "." + extensionValue)
    if (exportTypeVal === "allRecords") {
      excelExport("allRecords")
    } else if (exportTypeVal === "currentView") {
      excelExport("currentView")
    }
  }

  const portAttr = {
    startIcon: <UploadIcon style={{ width: "16px", paddingLeft: "2px" }} />,
    variant: "outlined",
    onClick: () => handleClick(),
    style: {
      width: "100%", padding: "5px 10px", marginBottom: "0px", maxWidth: "unset", color: "#9A5F85",
      borderColor: "#9A5F85"
    },
    disabled: disabled
  }

  return (
    <>
      <ExcelExport data={exportFormatData} fileName={fileName} ref={_export} />
      <PortButton {...portAttr}>{t(label)}</PortButton>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth={"lg"}
        className={classes.DialogMainWrapper}

      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ padding: "10px 20px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t('Export')}
            <IconButton size="small" onClick={() => handleClose()}>
              <CloseRounded />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers style={{ padding: "20px 32px" }}>
          <Typography style={{ fontWeight: "500", fontSize: "16px" }}>
            {t('exportHead')}
          </Typography>
          <Typography
            style={{
              fontWeight: "400",
              fontSize: "14px",
              marginTop: "16px",
              lineHeight: "20px",
            }}
          >
            {t('exportDescription')}
          </Typography>
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={exportTypeVal}
            onChange={(e) => setExportTypeVal(e.target.value)}
            className={classes.radioGroupWrap}
          >
            <Card className={classes.card} style={{ marginRight: "12px" }}>
              <div className={classes.radioBtn}>
                <FormControlLabel
                  value="currentView"
                  control={<Radio style={{ color: 'var(--primaryColor)' }} />} />
              </div>
              <div className={classes.imgWrap}>
                <img src="/static/img/currentView.svg" alt="Current View" />
              </div>
              <div>
                <Typography className={classes.firstHead}>
                  {t('Current View')}
                </Typography>
                <Typography className={classes.secondHead}>
                  {t('currentDes')}
                </Typography>
              </div>
              <div className={classes.clr10}></div>
            </Card>
            <Card className={classes.card} style={{ marginLeft: "0px", }}>
              <div className={classes.radioBtn}>
                <FormControlLabel
                  className={classes.radioBtn}
                  value="allRecords"
                  control={<Radio style={{ color: 'var(--primaryColor)' }} />}
                />
              </div>
              <div className={classes.imgWrap}>
                <img src="/static/img/allRecords.svg" alt="All Records" />
              </div>
              <div>
                <Typography className={classes.firstHead}>
                  {t('All Records')}
                </Typography>
                <Typography className={classes.secondHead}>
                  {t('Export All records')}.
                </Typography>
              </div>
            </Card>
          </RadioGroup>
          <div style={{ marginTop: "20px" }}>
            <Typography style={{ marginBottom: "5px" }}>
              {t('File format')}:
            </Typography>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={extensionValue}
              onChange={(e) => setExtensionValue(e.target.value)}
              variant="outlined"
              style={{ width: "100%", height: "45px" }}
              placeholder="Select"
              className={classes.selectField}
            >
              <MenuItem value="xls">{t('Excel')}</MenuItem>
              <MenuItem value="xlsx">{t('Excel Open XML')}</MenuItem>
              <MenuItem value="csv">{t('Comma Separated Values')}</MenuItem>
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "30px 0px 0px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setExtensionValue("xls")
                setExportTypeVal("")
              }}
            >
              {t('CANCEL')}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleExport()}
              style={{ backgroundColor: "var(--primaryColor)", color: "#fff" }}
              disabled={exportTypeVal === "" || extensionValue === ""}
            >
              {t('EXPORT')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export { ExportButtonUI }
export default ExportButtonUI
