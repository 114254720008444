/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import { withTheme } from "styled-components/macro"
import * as Yup from "yup"
import { Formik } from "formik"
import { stubData } from "@fixture"
import { Helmet } from "react-helmet"
import { CheckCircleIcon, Edit, Slash } from "@iconsGallery"
import { useListPageState } from "@state"
import {
  IconButton,
  TextField,
  Grid,
  Dialog,
  Modal,
  Card,
  Typography,
  Divider,
  InputAdornment,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import { spacing } from "@material-ui/system"
import * as Adm from "@adm"
import * as Utils from "@utils"
import { useTranslation } from "react-i18next"
import * as constantsAPI from "@constants"
import _ from "lodash"
import useStyles from "./styles"
import CustomHeaderContainer from "../CustomHeaderContainer"
import AttributeTypeCheck from "./AttributeTypeCheck"
import SharedTable from "../../../sharedComponents/Table"
import columns from "./columns"
import dummyData from "../../ProductAttribute/attributeData.json"
import { productDataApiMethods } from "../../../../../services/masterData/products/api"
import AttributeLookUpTable from "./AttributeLookUpTable"

const FormMainWrapper = styled.form`
  @media (min-width: 1024px) and (max-width: 1540px) {
    .cls-location-name-wrapper {
      border: none;
    }
    .MuiGrid-grid-lg-3 {
      max-width: 25% !important;
      flex-basis: 50% !important;
    }
  }
`

const ProductAttribute = (props) => {
  const {
    attributes,
    attributesFieldList = [],
    setAllowPrompt,
    isWriteAllowed,
    isAccordian = false,
    openAttrModal = false,
    handleClose = () => {},
    handleOpen = () => {},
    formikSetFieldValue = () => {},
    edit = false,
    setEdit = () => {},
    iconDisplay = true,
    pageMode = "create",
  } = props || {}
  const [gridState, setGridState] = useState({})
  const { t } = useTranslation()
  const countries = stubData.data.country.data
  const history = useHistory()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [isOpenDialog, setOpenDialog] = useState(false)
  const [isAnyChanges, setAnyChanges] = useState(false)
  const [attributeSet, setAttributeSet] = useState(attributes)
  const [showDeleteIcon, setShowDeleteIcon] = useState(true)
  useEffect(() => {
    setAttributeSet(attributes)
  }, [attributes])

  const dataState = {
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [],
    take: 10,
    skip: 0,
  }
  const adopterFunction = (
    dataState,
    page,
    limit,
    additionalFilters = [],
    searchObj = {}
  ) => {}

  const locationGlobalState = useListPageState({ dataState, columns }) || {}
  const {
    alert,
    alertMessage,
    alertType,
    columnData,
    setAlert,
    setAlertMessage,
    setAlertType,
    setColumnData,
    rowData,
    items,
    setItems,
    setRowData,
    selection,
    setSelection,
  } = locationGlobalState

  useEffect(() => {
    const storeData = {
      data: countries.map((dataItem) =>
        Object.assign(
          {
            selected: selection.store_ids.includes(dataItem.store_id)
              ? true
              : false,
            inEdit: false,
          },
          dataItem
        )
      ),
      editID: null,
      defaultID: null,
    }
    setRowData(storeData)
    productDataApiMethods.searchAttributes()
  }, [gridState])

  const dataFetchMethod = (bodyData) => {
    const userData = dummyData
    return { data: userData }
  }
  const StyledDialog = withStyles((theme) => ({
    paper: {
      minHeight: "90vh !important",
      maxHeight: "90vh !important",
      minWidth: "150vh !important",
      maxWidth: "170vh !important",
      padding: "0px",
    },
  }))(Dialog)
  const stateProps = { rowData, setRowData }
  const tableLocationAttributes = {
    tableGlobalState: locationGlobalState,
    detailPageRoute: ``,
    reDirectPath: "",
    editVariable: "",
    history,
    isType: "",
    stateProps,
    isCustomFunc: true,
  }

  const handleAttribute = (selectedItems) => {
    let tempAttrSelectedItems = []
    !_.isEmpty(selectedItems) &&
      selectedItems.map((x) => {
        let value = ""
        if (
          x.attribute_field_type === "List" ||
          x.attribute_field_type === "Single Choice"
        ) {
          let defalutcheck = []
          defalutcheck = _.filter(
            x.attribute_values,
            _.matches({ default: true })
          )?.[0]
          value = x.values || defalutcheck
        } else if (x.attribute_field_type === "Multi Choice") {
          let defalutcheck = []
          _.map(
            _.filter(x.attribute_values, _.matches({ default: true })),
            (o, idx) => {
              defalutcheck.push(o?._id)
            }
          )
          value = x.values || defalutcheck
        } else if (x.attribute_field_type === "Boolean") {
          value = x.values || false
        } else {
          value = x.values || ""
        }
        tempAttrSelectedItems.push({
          attribute_name: x.attribute_name,
          attribute_field_type: x.attribute_field_type,
          attribute_values: x.attribute_values,
          values: value,
          attributeId: x._id,
          _id: x._id,
          isDeleted: x.isDeleted,
        })
      })
    setAttributeSet(tempAttrSelectedItems)
    formikSetFieldValue("attributes", tempAttrSelectedItems)
    handleClose()
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          attributes: attributeSet,
        }}
        onSubmit={(values) => {
          const data = {
            ...attributeSet,
            ...values,
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          resetForm,
          handleReset,
        }) => {
          return (
            <>
              <StyledDialog
                open={openAttrModal}
                aria-labelledby="parent-modal-title"
                style={{ cursor: "pointer" }}
                aria-describedby="parent-modal-description"
                maxWidth="xl"
                onClose={() => handleClose()}
                disableBackdropClick
              >
                <SharedTable
                  title="Attribute List"
                  isPageView
                  isWriteAllowed={isWriteAllowed}
                  noShowPrimaryWithoutSelect
                  additionalButtons={[]}
                  dataFetchMethod={productDataApiMethods.searchAttributes}
                  responseDataPath="data.data"
                  additionalFilters={[]}
                  isLookUp
                  lookupType="elasticSearch"
                  columns={columns}
                  preSelectedItems={attributeSet}
                  // deleteAnId={deleteAnId}
                  // deleteMultipleId={deleteMultipleId}
                  // deleteMultiple={true}
                  tableFilter={false}
                  showSearchBar={true}
                  handleClose={handleClose}
                  onPrimaryActionClick={handleAttribute}
                  tableLocationAttributes={tableLocationAttributes}
                  customEditFunc={true}
                  // handleCustomFunc={handleEditModalOpen}
                />
              </StyledDialog>
              <FormMainWrapper style={{ width: "100%", marginTop: "10px" }}>
                <div className={isAccordian ? classes.card : ""}>
                  {isAccordian ? (
                    <Adm.AccordianPanel
                      isWriteAllowed={isWriteAllowed}
                      iconDisplay={iconDisplay}
                      renderHeader={(containerProps) => {
                        return (
                          <CustomHeaderContainer
                            edit={edit}
                            setEdit={setEdit}
                            isAnyChanges={isAnyChanges}
                            setAnyChanges={setAnyChanges}
                            containerProps={containerProps}
                            setFieldValue={setFieldValue}
                            data={values}
                            resetForm={resetForm}
                            label="Attribute"
                            setAllowPrompt={setAllowPrompt}
                            updateData={handleSubmit}
                            openModal={openAttrModal}
                            // setopenModal={setopenModal}
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            showDeleteIcon
                            pageMode={pageMode}
                          />
                        )
                      }}
                      renderContainer={(containerProps) => {
                        return (
                          <AttributeLookUpTable
                            containerProps={containerProps}
                            setFieldValue={setFieldValue}
                            formikSetFieldValue={formikSetFieldValue}
                            values={attributeSet}
                            resetForm={resetForm}
                            edit={edit}
                            setAnyChanges={setAnyChanges}
                            setAllowPrompt={setAllowPrompt}
                            isEditProduct={!edit}
                            handleChange={handleChange}
                            errors={errors}
                            handleBlur={handleBlur}
                            touched={touched}
                            openModal={openAttrModal}
                            handleClose={handleClose}
                            isAccordian={isAccordian}
                            handleAttributeSet={handleAttribute}
                          />
                        )
                      }}
                      label="Attributes"
                    />
                  ) : (
                    <AttributeLookUpTable
                      setFieldValue={setFieldValue}
                      formikSetFieldValue={formikSetFieldValue}
                      values={attributeSet}
                      resetForm={resetForm}
                      edit={edit}
                      setAnyChanges={setAnyChanges}
                      setAllowPrompt={setAllowPrompt}
                      isEditProduct={!edit}
                      handleChange={handleChange}
                      errors={errors}
                      handleBlur={handleBlur}
                      touched={touched}
                      openModal={openAttrModal}
                      handleClose={handleClose}
                      isAccordian={isAccordian}
                      handleAttributeSet={handleAttribute}
                    />
                  )}
                </div>
              </FormMainWrapper>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default ProductAttribute
