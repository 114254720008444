import React, { useEffect, useState, Suspense } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Helmet } from "react-helmet"
import DateFnsUtils from "@date-io/date-fns"
import { Router, Route } from "react-router-dom"
import { createBrowserHistory } from "history"
import { ToastContainer } from "react-toastify"
import _, { isEmpty } from "lodash"
import "react-toastify/dist/ReactToastify.css"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import { ThemeProvider } from "styled-components/macro"
import { create } from "jss"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles"
import createTheme from "./theme"
import Routes from "./routes/Routes"
import { QueryParamProvider } from "use-query-params"
import {
  userKeycloakAttributes,
  userKeycloakBeforeModifyAttributes,
} from "./reduxSagas/admin/actions"
import jwt_decode from "jwt-decode"
import UserService from "./services/UserService"
import Spinner from "./components/Spinner"
import { DashboardContext } from "./Dashboardcontext/DashboardContext"
import ForgotPasswordForm from "./pages/auth/ForgotPasswordForm"
import UnAuthorizedUser from "./pages/auth/UnAuthorizedUser"
import userApi from "./services/user/api"
import AppLogout from "./layouts/AppLogout"
import ResetPasswordForm from "./pages/auth/ResetPasswordForm"
import CustomRedirect from "./CustomRedirect"
import { useTranslation } from "react-i18next"
import { useGlobalStates } from "@state"
import * as Utils from "@utils"
import lang_rlt from "./lang_rlt"
import "./globalstyles.css"
import "./i18/i18next"
import { API_UM_URL } from "./utils/url"
import { decryptData } from "./components/AdmKit/Decoding/Decryption"

const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const { headerAxios } = ServiceAPI
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
})
export const history = createBrowserHistory()
window["historyW"] = history
const renderDynamicStylesColor = (props) => {
  return (
    <style>
      {`
        ${lang_rlt}
        .k-animation-container.k-animation-container-relative {
          z-index: 1350 !important;
        }
        :root {
          --primaryColor: #df86bb;
          --darkColor: #140316;
          --bgColor: #ffeaf7;
          --avatarBgColor: #f3f7fc;
        }
      `}
    </style>
  )
}

function App() {
  const { i18n, t } = useTranslation()
  const theme = useSelector((state) => state.themeReducer)
  const [isLoader, setLoader] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [isActive, setIsActive] = React.useState(true)
  const [lang, setLang] = useState("")
  const unAuthorizedRoleArr = ["Store Admin", "Store Associate"]
  const [roleName, setRoleName] = useState("")
  const projectName = process.env.REACT_APP_PROJECT_NAME
  const appLogoutTime = process.env.REACT_APP_TIMEOUT
  const dispatch = useDispatch()
  const getGlobalAppStates = useGlobalStates() || {}
  const getAttibutesData = (data, role) => {
    let arrAtt = Object.entries(data)
    const modifyTempFormat = []
    _.map(arrAtt, (x, i) => {
      let removekeyArr = x?.[0]?.split("***")
      if (x?.[1]?.[0] === "Y") {
        modifyTempFormat.push(removekeyArr[1])
      }
    })

    const tempFormat = _.reduce(
      data,
      (acc, val, key) => {
        let removekeyArr = key.split(role + "***")
        let keyArr = removekeyArr[1].split("___")
        let permBool = val[0] === "Y"
        let path = _.join(keyArr, ".")
        _.set(acc, path, permBool)
        return acc
      },
      {}
    )
    dispatch(userKeycloakAttributes(tempFormat))
    dispatch(userKeycloakBeforeModifyAttributes(modifyTempFormat))
  }

  const getAttibutesSassData = (data) => {
    const tempFormat = _.reduce(
      data,
      (acc, val, key) => {
        let keyArr = key.split("___")
        let permBool = val[0] === "Y"
        let path = _.join(keyArr, ".")
        _.set(acc, path, permBool)
        return acc
      },
      {}
    )
    dispatch(userKeycloakAttributes(tempFormat))
  }
  const { location = {} } = window || {}

  useEffect(() => {
    let lang = localStorage.getItem("lang")
    let language_display_direction = localStorage.getItem(
      "language_display_direction"
    )

    window["i18n"] = i18n

    if (lang) {
      i18n.changeLanguage(lang)
      setLang(lang)
    }
    window["languagedmrtl"] = false
    if (language_display_direction === "rtl") {
      window["languagedmrtl"] = true
      document.querySelector("body").setAttribute("id", "id-rlt")
    }
    if (
      location?.pathname.includes("auth") === false &&
      location?.pathname.includes("resetpassword") === false
    ) {
      i18n.init({
        resources: JSON.parse(localStorage.getItem("translationData")),
      })
    }
  }, [localStorage.getItem("lang")])

  useEffect(() => {
    if (!window.location.pathname.includes('forgetpassword') && (!window.location.pathname.includes('resetpassword'))) {
      const fetchData = async () => {
        try {
          const postObj = { refresh_token: `${localStorage.getItem("refreshToken")}` }
          if (postObj) {
            const res = await headerAxios.post(API_UM_URL + API_END_POINTS.refreshTokenAPI, postObj);
            const hashedMessage = res?.data
            let responseData = decryptData(hashedMessage);
            localStorage.setItem("token", responseData?.access_token)
            localStorage.setItem("refreshToken", responseData?.refresh_token)
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
      const timeInterval = (Number(localStorage.getItem("expires_in"))) * 1000
      const intervalId = setInterval(fetchData, Number(timeInterval));

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(async () => {
    if (!window.location.pathname.includes('forgetpassword') && !(window.location.pathname.includes('resetpassword'))) {
      if (UserService.isLoggedIn()) {
        let mydata = UserService.getToken((res) => {
          return res
        })
        let decoded = jwt_decode(mydata)
        setLoader(true)

        localStorage.setItem("userName", decoded.name)
        localStorage.setItem("userEmail", decoded.preferred_username)
        localStorage.setItem("firstName", decoded.given_name)
        localStorage.setItem("lastName", decoded.family_name)
        let roleArray = decoded.userRole
        let isSAASRolePresent = false
        let loginRole = ""
        roleArray?.map((x) => {
          if (x?.includes("SaaS-Site-Admin")) {
            loginRole = x
            return (isSAASRolePresent = true)
          }
        })
        if (!isSAASRolePresent) {
          userApi.getUserInfo().then((r) => {
            const hashedMessage = r?.data?.data
            let responseData = decryptData(hashedMessage);
            localStorage.setItem("userId", responseData?._id)
          })
        }
        if (
          !isSAASRolePresent &&
          (decoded?.Tenant?.[0] === undefined || decoded?.Tenant?.[0] === null)
        ) {
          UserService.doLogout()
          localStorage.clear()
        } else {
          let defaultRoleStatus = false

          const myfile = _.map(roleArray, (x, i) => {
            if (
              x !== undefined &&
              x.split("-")?.[0] === "default" &&
              x.split("-")?.[1] === "roles"
            ) {
              defaultRoleStatus = true
              roleArray.splice(i, 1)
            }
          })

          let myRole = _.filter(roleArray, (x) => x !== "Customer")
          if (myRole.length === 0 || myRole === undefined) {
            UserService.doLogout()
            localStorage.clear()
          } else {
            localStorage.setItem("token", mydata)
            if (_.includes(myRole, "SaaS-Site-Admin") || isSAASRolePresent) {
              setRoleName(loginRole)
              localStorage.setItem("role", loginRole)
              let resSassResult = await ServiceAPI.getCommonRoleData(
                API_END_POINTS.getSassAdminRoles + "/" + loginRole
              )
              if (resSassResult.status === 200) {
                let attributes = _.get(resSassResult, "data.data.attributes", {})
                localStorage.setItem("currencySymbol", "$")
                let language = "en"
                localStorage.setItem("lang", language)
                document.querySelector("html").setAttribute("lang", language)
                setLang(language)
                setLoader(false)
                getAttibutesSassData(attributes, loginRole)
              } else {
                setLoader(true)
                UserService.doLogout()
                localStorage.clear()
              }
            } else {
              let tenantResponse = await ServiceAPI.getKCRoleData(
                API_END_POINTS.getTenantIdByName + decoded.Tenant[0]
              )
              //decode Method
              const hashedMessage = tenantResponse?.data?.data
              if (tenantResponse.status === 200) {
                let tenantData = decryptData(hashedMessage);
                localStorage.setItem(
                  "tenantCountry", tenantData?.address?.country || ""
                )
                localStorage.setItem("tenantName", tenantData?.tenantName)
                localStorage.setItem("tenantId", tenantData?.tenantId)
                localStorage.setItem("taxProvider", tenantData?.taxProvider?.name)
                localStorage.setItem(
                  "currencySymbol",
                  tenantData?.currencies?.[0]?.symbolCode?.props?.children || "$"
                )
                if (localStorage.getItem("lang") === null) {
                  localStorage.setItem(
                    "lang",
                    tenantData?.language?.find((x) => x.default)?.language_code ||
                    tenantData?.language?.[0]?.language_code ||
                    "en"
                  )
                  setLang(
                    tenantData?.language?.find((x) => x.default)?.language_code ||
                    tenantData?.language?.[0]?.language_code ||
                    "en"
                  )
                }

                let body = {
                  tenantName: tenantData?.tenantName,
                  userId: decoded?.sub,
                }

                /**
                 * Get Attributes from Role based
                 */
                let res = await ServiceAPI.fetchKCRoleData(
                  API_END_POINTS.getRolePermissionDetail,
                  body
                )

                setRoleName(_.get(res, "data.data.roleName", ""))
                myRole = _.get(res, "data.data.roleName", {})
                localStorage.setItem("role", _.get(res, "data.data.roleName", {}))

                let attributes = _.get(res, "data.data.attributes", {})
                if (res.status === 200 && !isEmpty(attributes)) {
                  setLoader(false)
                  getAttibutesData(attributes, myRole)
                } else {
                  setLoader(true)
                  UserService.doLogout()
                  localStorage.clear()
                }
              }
            }
          }
        }
      } else {
        UserService.doLogin()
      }
      document.addEventListener("click", tokenDetails)
      setInterval(() => {
        tokenDetails()
      }, 600000)
    }

  }, [UserService.isLoggedIn()])

  const tokenDetails = () => {
    userApi
      .getTokenDetails()
      .then((resp) => { })
      .catch((error) => {
        console.error("An error occurred while fetching token details:", error)
        UserService.doLogout()
        localStorage.clear()
      })
  }

  const renderAppLogout = () => {
    return (
      <AppLogout
        onActive={() => {
          setIsActive(true)
        }}
        onIdle={() => {
          setIsActive(false)
        }}
        appLogoutTime={appLogoutTime || 1000*60*10}
      />
    )
  }
  const renderAppKit = () => {
    return (
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <ToastContainer />
              {projectName === "KnapCart" &&
                _.includes(unAuthorizedRoleArr, roleName) ? (
                <UnAuthorizedUser />
              ) : (
                <>
                  <Utils.GlobalAppContext.Provider
                    value={{ ...getGlobalAppStates }}
                  >
                    <Router history={history}>
                      {<CustomRedirect history={history} lang={lang} t={t} />}

                      <Route
                        path="/auth/forgetpassword"
                        exact={true}
                        render={() => <ForgotPasswordForm history={history} />}
                      />
                      { }
                      <Route
                        path="/password/resetpassword"
                        exact={true}
                        render={() => <ResetPasswordForm history={history} />}
                      />
                      <DashboardContext.Provider
                        value={{ showMore, setShowMore }}
                      >
                        <QueryParamProvider ReactRouterRoute={Route}>
                          {isLoader ? <Spinner /> : <Routes />}
                        </QueryParamProvider>
                      </DashboardContext.Provider>
                    </Router>
                  </Utils.GlobalAppContext.Provider>
                </>
              )}
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    )
  }
  return (
    <React.Fragment>
      {renderDynamicStylesColor()}
      {renderAppLogout()}
      <Helmet titleTemplate="%s | Digitkart" defaultTitle="Digitkart" />
      {localStorage.getItem("lang") !== null ||
        localStorage.getItem("lang") === "password" ? (
        renderAppKit()
      ) : (
        <Spinner />
      )}
    </React.Fragment>
  )
}
export default App
