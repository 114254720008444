import React, { useState, useEffect } from "react"
import {
  Grid,
} from "@material-ui/core"
import * as Adm from "@adm"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "@utils"
import { useDispatch, useSelector } from "react-redux"
import * as constantsAPI from "@constants"
import _ from "lodash"
import { APIUrl } from "@utils"
import DraggableTreeView from "../../components/DraggableTreeView"
import { CategoryForm } from "../../FeaturesKit/ProductHierarchy/CategoryForm"
import productsAPI from "../../services/masterData/products/api"
import { JSUtils } from "@utils"

import {
  getProcessedFlatData,
  getNewFlatDataWithOldExpandedStatus,
  getTreeData,
  getFlatData,
  getAllChildrenIds,
} from "../../components/DraggableTreeView/treeDataUtils"
import { fetchAllHierarchy } from "../../services/masterData/locations/actions"

const { API_URL_STORES } = APIUrl || {}
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const initialFormProps = { isOpen: false, data: {} }
const initSnackbarProps = {
  isOpen: false,
  message: "",
  type: "info",
  autoHideDuration: 2500,
}

const HierarchyEditorUI = (props) => {

  const dispatch = useDispatch()
  const { categories } = useSelector((state) =>
    _.get(state, "locationHierarchyReducer", {})
  )
  const [formProps, setFormProps] = useState({ ...initialFormProps })
  const [flatTreeData, setFlatTreeData] = useState(() => {
    return categories?.list || []
  })
  const [treeData, setTreeData] = useState(getTreeData(flatTreeData))
  const [processedFlatData, setProcessedFlatData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [snackbarProps, setSnackbarProps] = useState(initSnackbarProps)
  const [height, width] = useWindowSize()
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [allowAlcoholUpdate, setAllowAlcoholUpdate] = useState(false)
  const [allowUpdate, setAllowUpdate] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedNodeId, setSelectedNodeId] = useState()


  const handleFormClose = () => {
    setFormProps({ ...initialFormProps })
  }

  const handleFormOpen = () => {
    setFormProps({
      ...initialFormProps,
      isOpen: true,
      mode: "create",
      openNewNode: {},
    })
  }

  const handleViewOpen = (categoryData) => {
    if (props.isComInProductMapping) {
      setSelectedNodeId(categoryData._id)
      props.setCategory(categoryData)
    } else
      setFormProps({
        isOpen: true,
        mode: "view",
        data: categoryData,
        openNewNode: {},
      })
  }

  const handleAddNode = (categoryID) => {
    setFormProps({
      isOpen: true,
      mode: "create",
      data: {
        mapped_parent_categories: _.isArray(categoryID)
          ? categoryID
          : [categoryID],
      },
    })
  }

  const handleNodeDelete = (id) => {
    productsAPI
      .deleteCategory(id)
      .then((resp) => {
        fetchNewFlatTreeData()
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(resp?.data, t, "Successfully Deleted!"),
          type: "success",
        })
      })
      .catch((err) => {
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(err?.data, t, "Sorry! Delete operation failed"),
          type: "error",
        })
      })
  }

  const fetchNewFlatTreeData = (oldFlatData = getFlatData(treeData)) => {
    dispatch(fetchAllHierarchy())
  }

  const handleNodeMove = (newParentData, nodeData) => {
    let tempData = nodeData
    let tempParentID = _.compact([
      newParentData?.category_id,
      ...(newParentData?.mapped_parent_categories || []),
    ])
    nodeData.mapped_parent_categories = tempParentID
    productsAPI
      .updateCategory(nodeData?.category_id, nodeData)
      .then((resp) => {
        fetchNewFlatTreeData(getFlatData(treeData))
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(resp?.data, t, "Updated Successfully!"),
          type: "success",
        })
      })
      .catch((err) => {
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(err?.data, t, "Sorry! Update operation failed"),
          type: "error",
        })
      })
  }

  const handleCheckboxClick = (node, parentNode) => {
    let exstIdx = _.findIndex(
      selectedCategories,
      (o) => o === node?.category_id
    )
    let childrenIds = getAllChildrenIds(node) || []
    let allIdsOfNode = [node?.category_id, ...childrenIds]
    let temp
    if (exstIdx === -1) {
    } else {
      temp = _.without(selectedCategories, ...(allIdsOfNode || []))
    }
    setSelectedCategories(temp)
    setProcessedFlatData(
      getProcessedFlatData({ data: getFlatData(treeData), checkedList: temp })
    )
  }

  useEffect(fetchNewFlatTreeData, [])

  useEffect(() => {
    setProcessedFlatData(
      getProcessedFlatData({
        data: flatTreeData,
        checkedList: selectedCategories,
      })
    )
  }, [flatTreeData])

  useEffect(() => {
    setFlatTreeData(
      getNewFlatDataWithOldExpandedStatus(
        categories?.list || [],
        getFlatData(treeData)
      )
    )
  }, [categories?.list])

  useEffect(() => {
    let temp = getTreeData(processedFlatData)
    setTreeData(temp)
  }, [processedFlatData])

  return (
    <>
      <Grid
        container
        item
        xs={12}
        style={{ border: `1px solid #e0e0e0`, height: `100%` }}
      >
        <Adm.BackdropOverlay open={loading} />
        <Grid container>
          <Grid item xs={formProps?.isOpen ? 4 : 12}>
            <DraggableTreeView
              isViewMode={false}
              treeData={treeData}
              onChangeInTreeData={setTreeData}
              handleCheckboxClick={handleCheckboxClick}
              onAddClick={handleFormOpen}
              flatTreeData={processedFlatData}
              handleClickNode={handleViewOpen}
              handleAddNode={handleAddNode}
              handleNodeMove={handleNodeMove}
              handleNodeDelete={handleNodeDelete}
              selectedNodeId={selectedNodeId}
              isComInProductMapping={
                props.isComInProductMapping && props.isComInProductMapping
              }
              canDragPropInProductMapping={
                props.canDragPropInProductMapping &&
                props.canDragPropInProductMapping
              }
              showAddRootNodeButton={props.showIcon === false ? false : true}
            />
          </Grid>
          {formProps?.isOpen && (
            <Grid item xs={8} style={{ borderLeft: `1px solid #e0e0e0` }}>
              {" "}
              <CategoryForm
                formProps={formProps}
                setFormProps={setFormProps}
                onClose={handleFormClose}
                data={formProps?.data || {}}
                fetchNewFlatTreeData={fetchNewFlatTreeData}
              />{" "}
            </Grid>
          )}
        </Grid>
      </Grid>
      {snackbarProps?.isOpen && (
        <Adm.SnackBar
          open
          message={snackbarProps?.message || "Uh! May be wait!?"}
          type={snackbarProps?.type || "info"}
          icon={snackbarProps?.icon || undefined}
          handleClose={() => setSnackbarProps(initSnackbarProps)}
        />
      )}
    </>
  )
}

export default HierarchyEditorUI
export { HierarchyEditorUI }
