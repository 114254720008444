const CryptoJS = require("crypto-js")

const SOME_ERROR_OCCURED = "Some error occurred"
const secretKey =
  "nTasAnJN5C4!nPrM9uWg9J2*fKHfOD3*PQp0ita@4s@zBD!Tz!8Zlbm2@K9aWStj"

export const decryptData = (encryptedData) => {
  const isJSON = (data) => {
    if (typeof data === "object") {
      return true // Already a JavaScript object, so it's JSON
    }
    try {
      JSON.parse(data) // Try to parse as JSON if it's a string
      return true
    } catch (e) {
      return false // Parsing failed, so it's not valid JSON
    }
  }

  // If the data is valid JSON, return it as-is
  if (isJSON(encryptedData)) {
    return encryptedData
  }

  try {
    const key = CryptoJS.enc.Utf8.parse(secretKey)
    const encrypted = CryptoJS.enc.Base64.parse(encryptedData)
    const salt = CryptoJS.lib.WordArray.create(encrypted.words.slice(0, 4))
    const iv = CryptoJS.lib.WordArray.create(encrypted.words.slice(4, 8))
    const ciphertext = CryptoJS.lib.WordArray.create(encrypted.words.slice(8))
    const derivedKey = CryptoJS.PBKDF2(key.toString(CryptoJS.enc.Utf8), salt, {
      keySize: 256 / 32,
      iterations: 100000,
    })

    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: ciphertext },
      derivedKey,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    )

    const plaintext = decrypted.toString(CryptoJS.enc.Utf8)
    return JSON.parse(plaintext)
  } catch (error) {
    return {
      status: "failed",
      message: SOME_ERROR_OCCURED,
      messagecode: [{ code: "70004" }],
      error,
    }
  }
}
