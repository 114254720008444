import * as ServiceAPI from "@services"
import * as Utils from "@utils"
import * as TransformModal from "./transformModal"
import _ from "lodash"
import { decryptData } from "../../../AdmKit/Decoding/Decryption"

export const getAPIRetailerList = (props) => {
  const {
    identifer = "AS",
    setFetchAPIRetailerList,
    isActiveSyncLoad = false,
    setIsSyncLoad,
    setIsLoading,
    pageNumber = 0,
    rowsPerPage = 15,
    searchText = "",
  } = props || {}

  setIsLoading(true)
  if (isActiveSyncLoad) {
    setIsSyncLoad(true)
  }

  let data = {
    END_POINTS: `/api/dashboardInfo/getTenantsInfo`,
    body: {
      limit: rowsPerPage,
      searchText: searchText,
      page: pageNumber,
    },
  }

  ServiceAPI.doPostFetchAPI(data)
    .then((response) => {
      const hashedMessage = response?.data?.data
      let decryptedResponse = decryptData(hashedMessage);
      setFetchAPIRetailerList({
        docs: Utils.JSUtils.isEmpty(decryptedResponse?.docs)
          ? []
          : TransformModal.retailerList(decryptedResponse?.docs),
        total: decryptedResponse?.totalDocs,
      })

      setIsLoading(false)
      if (isActiveSyncLoad) {
        setIsSyncLoad(false)
      }
    })
    .catch((error) => {
      console.log("error", error)
      setIsLoading(false)
      setFetchAPIRetailerList({ docs: [], total: "" })
      if (isActiveSyncLoad) {
        setIsSyncLoad(false)
      }
    })
}
